import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import authService from '../_services/AuthService';
import { HomeTitle } from './HomeTitle';
import UserContext from './context/UserContext';
import Messages from './Messages/Messages';
import SignalRManager from './SignalRManager';
import DOMPurify from 'dompurify';
import { NotificationManager } from 'react-notifications';
import CloseButton from './../components/CloseButton'



const defaultRequest = { shortDescription: 'Send', placeholder: "Type your message here", id: 0, questionTypeId: 1 }

const GetInvolved = () => {

    const [selectedTab, setTab] = useState(0);
    const [selectedRequest, setSelectedRequest] = useState(defaultRequest);
    const [requestTypeList, setRequestType] = useState([]);
    const [messageList, setMessageList] = useState([]);
    const [message, setMessage] = useState("");
    const [isLoadingMessages, setLoadingMessages] = useState(false);
    const [msgLength, setMsgLength] = useState(0);
    const [msgMaxLength, setMsgMaxLength] = useState(500);
    const [disableSend, setDisableSend] = useState(false);

    const context = useContext(UserContext);

    const { seenAndHeard, inRoomSeenAndHeard } = context.switches;
    const { attendanceStatus, qAState } = context.fullState;

    useEffect(() => {
        document.title = "CESJoinIN - Get involved";   

        SignalRManager.addReceiveMessageListener(receivedamessage);
        if (requestTypeList.length == 0 && (seenAndHeard || inRoomSeenAndHeard)) {
            getMeetingQuestionSetup(); 
        }
        if (qAState?.typedMessage) {
            setMessage(qAState.typedMessage);
            setTab(qAState.selectedTab)
        }
        fetchMessages();

        if (typeof (context.videoResize) === "function") {
            setTimeout(() => { context.videoResize() }, 1000)
        }
    }, []);


    const receivedamessage = () => {
        fetchMessages();
    }

    const getMeetingQuestionSetup = async () => {
        setLoadingMessages(true)
        const token = authService.getToken();
        const response = await fetch('VMGetMeetingQuestionSetup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        await response.json().then(meetingSetupList => {
            setRequestType(meetingSetupList.filter(x => x.enabled === true));
            setLoadingMessages(false)
        }).catch((error) => {
            console.log("error", "QA load");
            setLoadingMessages(false)
        })
    }



    const fetchMessages = async () => {
        let user = context.user.userID;
        const token = authService.getToken();
        let vmID = context.meeting.vmId;
        if (user && vmID) {
            let response = await fetch('VMAttendeeChatResponse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ 'VMeetingId': parseInt(vmID), 'userID': parseInt(user) })
            });

            await response.json().then(messages => {
                setMessageList(messages)
            }).catch((error) => {
                console.log("error", "QA load");
            })
        }
    }

    const onTabSet = (selectedTabIndex) => {
        setTab(selectedTabIndex);
        context.setQAState(selectedTab, message);
        if (selectedTabIndex !== 0) {
            let selectedRequest = requestTypeList[selectedTabIndex - 1];
            selectedRequest = { ...selectedRequest, placeholder:"Type your message here"}
            setSelectedRequest(selectedRequest);
        }
        else {
            setSelectedRequest(defaultRequest)

        }
    }

    const renderRequestTypeTabs = () => {
        return requestTypeList.map(requestType => {
            return (
                <Tab title={requestType.shortDescription} key={requestType.id}>{requestType.shortDescription}</Tab>
            )
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (message) {
            await sendMessage();
            if (selectedTab !== 0) {
                setTab(0);
                setSelectedRequest(defaultRequest);
            }
        }
    }

    const sendMessage = async () => {
        let userMessage = message;
        if (userMessage) {
            let trimmed = userMessage.trim();
            trimmed = trimmed.replace(/[\n]+/g, '');
            trimmed = trimmed.replace(/[\r]+/g, '');
            userMessage = trimmed;
            userMessage = DOMPurify.sanitize(userMessage);
            if (context.meeting.vmId && context.user.userID && userMessage.length > 0) {
                setDisableSend(true)
                const { userID, userName } = context.user;
                const { vmId } = context.meeting;
                let user = userName ? userName : userID
                let objChat = {
                    'VMeetingId': vmId,
                    'SubmittedById': parseInt(userID),
                    'QuestionText': userMessage,
                    'SubmittedByName': user,
                    'MessageTypeId': selectedRequest.questionTypeId,
                    'QuestionSetupId': selectedRequest.id
                }
                const token = authService.getToken();
                fetch("SubmitQuestion", {
                    method: "POST",
                    dataType: "JSON",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                    ,
                    body: JSON.stringify(objChat)
                })
                    .then((data) => {
                        setDisableSend(false)
                        if (data.status == 200) {
                            NotificationManager.info("Your message has been sent to a moderator and will be responded to shortly", "Message sent", 4000);
                            setMsgLength(0)
                            setMessage("");
                            context.setQAState(selectedTab, "");
                            fetchMessages();
                        }
                        else if (data.status == 429) {
                            NotificationManager.error("Our moderators are currently very busy, please try again later", "Message not sent", 4000);
                        }

                    }).catch((error) => {
                        setDisableSend(false)
                        console.log(error, "SubmitQuestion")
                    })
            }
        }

    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);           
        }
        setMsgLength(object.target.value.length);
        setMsgMaxLength(object.target.maxLength);
    }
     

    const renderRequestTypeTabPanels = () => {
        return requestTypeList.map(requestType => {
            return (
                <TabPanel key={requestType.id} className={requestType.shortDescription}>
                    <div className="inboxMessges bg-light">
                        <div className="row bg-light mx-0">
                            <span className="customMessage">
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(requestType.longDescription) }} />                                
                            </span>
                        </div>
                    </div>
                </TabPanel>
            )
        })
    }

    const renderUserMessageSection = () => {
        return (
            <div className="p-3 ">
                <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                    <div className="d-flex py-1">
                        <div className="flex-grow-1  border-secondary rounded">
                            <div className="form-floating border-0">
                                <input type="text"
                                    className="form-control"
                                    placeholder={selectedRequest?.placeholder}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value); 
                                        context.setQAState(selectedTab, e.target.value);
                                    }}
                                    maxLength="500"
                                    onInput={maxLengthCheck}
                                    onClick={() => { context.setMessageValues(false); }}                                   
                                    required
                                />
                                <label >{selectedRequest?.placeholder}</label>
                            </div>
                        </div>
                    </div>
                <div className="d-flex ">
                    <div className="flex-grow-1 text-danger ">
                        {msgLength > 0 && <div className="textLength">Message maximum {msgMaxLength} characters {msgMaxLength - msgLength} left</div>}
                    </div>
                        <button disabled={disableSend}  type="submit" className="btn btn-sm btn-primary" title="Submit your question/request here." >Send</button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="row scrollFix">
            <div className="getInvolved scrollContainer">
                <div className="getInvolvedSection">
                    <div className="d-flex justify-content-between">
                        <h2 className="ps-2 pt-3">
                            Q&A
                        </h2>
                        <CloseButton />
                    </div>
                    <div>
                        <div className="row mt-2">
                            <Tabs selectedIndex={selectedTab} onSelect={onTabSet}>
                                <TabList>
                                    <Tab title="Messages">{`Messages`}</Tab>
                                    {(attendanceStatus && inRoomSeenAndHeard) || (!attendanceStatus && seenAndHeard) ?
                                        renderRequestTypeTabs() :
                                        null
                                    }
                                </TabList>
                                <TabPanel>
                                    <div className="chatWindow">
                                        <div className="row bg-light mx-0 border-bottom border-grey">
                                            <span className="customMessage">
                                                You can communicate with the admin team by sending messages below.
                                            </span>
                                        </div>
                                        <div className="inboxMessges">
                                            <Messages messageList={messageList} />
                                        </div>
                                    </div>
                                </TabPanel>
                                {renderRequestTypeTabPanels()}
                                <div className="bg-light">
                                    {renderUserMessageSection()}
                                </div>
                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetInvolved;