import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { NavTopBar } from './components/NavTopBar';
import { Footer } from './components/Footer';

import { HomeTitle } from './components/HomeTitle';

import Home from './components/Home';
import DocumentList from './components/DocumentList';
import ContestSlider from './components/VotingParentPage';
import Results from './components/Results';
import Ticket from './components/Ticket';
import BlankComponent from './components/BlankComponent';

import { Privacy } from './components/Privacy';
import { Help } from './components/Help';
import { Accessibility } from './components/Accessibility';

import { LoginLayout } from './components/LayoutLogin';
import { ProxyLayout } from './components/LayoutProxy';
import { PolicyLayout } from './components/LayoutPolicy';

import { Login } from './components/login/Login';
import { LoginBypass } from './components/login/LoginBypass';

import { Logout } from './components/login/Logout'
import { DuplicateLogout } from './components/login/DuplicateLogout'
import { ForcedLogout } from './components/login/ForcedLogout'

import { CreateGuestUser } from './components/login/CreateGuestUser'
import { ForgotCodes } from './components/login/ForgotCodes'

import { PrivateRoute } from './components/login/PrivateRoute'
import { NotificationContainer } from 'react-notifications';
import { Attending } from './components/AttendingAndProxy/Attending'
import { Rescind } from './components/AttendingAndProxy/RescindVote'
import { ProxyPollCard } from './components/AttendingAndProxy/ProxyPollCard'
import { ProxyRouter } from './components/AttendingAndProxy/ProxyPageRouter'
import { PageLoadMonitor } from './components/PageLoadMonitor'
import { SignalRContextConsumer, SignalRController } from './components/SignalRComms'
import SignalRManager from './components/SignalRManager'
import 'react-notifications/lib/notifications.css';
import history from "./utils/history";
import { Forbidden } from './components/Forbidden';
import './custom.css'
import UserContext from './components/context/UserContext';
import { LeftLayout } from './components/LayoutMainColumns';
import { OpenChatContextProvider } from './components/OpenChat/Common/OpenChatContext/OpenChatContextProvider';
import GetInvolved from './components/GetInvolved'

//import SurveyParent from './components/Survey/SurveyParent';
import AudioVisual from './components/AudioVisual';
import { ZoomClientWrapper } from './components/Zoom/ZoomClientWrapper';

export { SignalRManager, SignalRController };

const NotFound = () => { return <div> <h2>404 - Not Found!</h2><p>The page you have requested has not been found, please check the address and try again.</p> </div> };


export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {

        };
        console.log("Attendee Version 3.8.0");
    }

    skipToContent = (e) => {
        e.preventDefault();
        if (document.getElementById('skipToContent')) {
            document.getElementById('skipToContent').focus();
            window.location.hash = '#skipToContent';
        }
    }


    render() {
        let attending = this.context.fullState.attendanceStatus;
        let switches = this.context.switches;
        let okToLoad = parseInt(this.context.user.userID) > 0 && parseInt(this.context.meeting.vmId);

        let showVideoStream = ((!attending && switches.enableAudioVideo) || (attending && switches.inRoomAudiovisual));

        let showAskAQuestion = this.context.user.isMember && ((!attending && switches.enableQA) || (attending && switches.inRoomChat))
        let showSurvey = !attending && ( switches.enableSurvey || this.context.fullState.surveyPopup );
        let backgroundImage = this.context.meeting.backgroundImage ? { backgroundImage: `url(data:image/jpeg;base64,${this.context.meeting.backgroundImage})` } : {};

        
        return (

            <div className="outerlimits">
                <a className="skiptocontent" href="#skipToContent" onClick={(e) => this.skipToContent(e)}>Skip to content</a>

                <NotificationContainer />
                <Router history={history}>

                    <Switch>
                        <Route path='/login/:path?' exact>
                            <div id="skipToContent" tabIndex="-1">
                                <LoginLayout>
                                    <Switch>
                                        <Route exact path='/login/' component={Login} />
                                        <Route exact path='/login/loginbypass' component={LoginBypass} />
                                        <Route path='/login/NGuest' component={CreateGuestUser} />
                                        <Route path='/login/forgot' component={ForgotCodes} />
                                        <Route path='/login/logout' component={Logout} />
                                        <Route path='/login/DuplicateLogout' component={DuplicateLogout} />
                                        <Route path='/login/ForcedLogout' component={ForcedLogout} />
                                        <PrivateRoute path='/login/Attending' component={Attending} />
                                        <PrivateRoute path='/login/RescindVote' component={Rescind} />
                                        <PrivateRoute path='/login/ProxyRouter' component={ProxyRouter} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </LoginLayout>
                            </div>
                        </Route>
                        <Route path='/proxy/:path?' exact>
                            <div id="skipToContent" tabIndex="-1">
                                <ProxyLayout>
                                    <PrivateRoute path='/proxy/ProxyPollCard' component={ProxyPollCard} />
                                </ProxyLayout>
                            </div>
                        </Route>
                        <Route path='/Privacy/:path?' exact>
                            <div id="skipToContent" tabIndex="-1">
                                <PolicyLayout>
                                    <Route path='/Privacy' component={Privacy} />
                                </PolicyLayout>
                            </div>
                        </Route>
                        <Route path='/Help/:path?' exact>
                            <div id="skipToContent" tabIndex="-1">
                                <PolicyLayout>
                                    <Route path='/Help' component={Help} />
                                </PolicyLayout>
                            </div>
                        </Route>
                        <Route path='/Accessibility/:path?' exact>
                            <div id="skipToContent" tabIndex="-1">
                                <PolicyLayout>
                                    <Route path='/Accessibility' component={Accessibility} />
                                </PolicyLayout>
                            </div>
                        </Route>
                        <Route path='/ZoomContainer' exact component={ZoomClientWrapper} />                            
                        
                        <Route path='/:path?'>
                            <Fragment>
                                <OpenChatContextProvider userName={this.context.user.userName}>
                                    {okToLoad > 0 && <SignalRContextConsumer history={history} />}
                                    <PageLoadMonitor />
                                    <div className="appParent">
                                        {this.context.user.test === "True" && !switches.demo && <div className="alert alert-secondary me-0 py-1" role="alert">You are logged in under test conditions, submitted votes will not be counted in final totals</div>}
                                        <div className="headerContent">
                                            <header>
                                                <div>
                                                    <div className="topbar">
                                                        <NavTopBar />
                                                    </div>
                                                    <div className="titlebar sr-only">
                                                        <HomeTitle />
                                                    </div>
                                                </div>
                                            </header>
                                        </div>
                                        <div className="mainContent py-1" style={backgroundImage} >
                                            <main id="skipToContent" tabIndex="-1">
                                                {/*<QuickPoll />*/}
                                                <div className="row fillheight flex-wrap-reverse ">
                                                    <LeftLayout showVideoStream={showVideoStream}>
                                                        <Switch>
                                                            <PrivateRoute exact path='/' component={Home} />
                                                            <PrivateRoute path='/DocumentList/:path?' component={((!attending && switches.enableDocuments) || (attending && switches.inRoomDocuments)) ? DocumentList : Forbidden} />
                                                            <PrivateRoute path='/Contests' component={((!attending && switches.remoteVoting) || (attending && switches.inRoomVoting)) ? ContestSlider : Forbidden} />
                                                            <PrivateRoute path='/Results' component={((!attending && switches.results && switches.enableVoting) || (attending && switches.inRoomResults && switches.enableVoting)) ? Results : Forbidden} />
                                                            <PrivateRoute path='/Ticket' component={attending && switches.ticket ? Ticket : Forbidden} />
                                                            <PrivateRoute path='/Video' component={showVideoStream ? BlankComponent : Forbidden} />
                                                            <PrivateRoute path='/Q&A' component={showAskAQuestion ? GetInvolved : Forbidden} />
                                                            {/*<PrivateRoute path='/Survey' component={showSurvey ? SurveyParent : Forbidden} />*/}
                                                            <Route component={NotFound} />
                                                        </Switch>
                                                    </LeftLayout>

                                                    {(showVideoStream && switches.meetingType) &&
                                                        <AudioVisual switches={switches} userName={this.context.user.userName} attending={attending} />
                                                    }
                                                </div>
                                            </main>
                                        </div>
                                        <div className="footerContent">
                                            <Footer />
                                        </div>
                                    </div>
                                </OpenChatContextProvider>
                            </Fragment>
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

App.contextType = UserContext;