import React, { Component, Fragment, useContext } from 'react';
import UserContext from './context/UserContext';
import Accordion from 'react-bootstrap/Accordion'
import { Row, Col, useAccordionButton } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import AccordionContext from 'react-bootstrap/AccordionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faCheckCircle, faBell } from '@fortawesome/free-solid-svg-icons'

import { ProxyOptions, ResolutionOptions } from './VotingResolutionComponents'
import { ProxyXvoteOptions, XvoteOptions } from './VotingXvoteComponents'
import DOMPurify from 'dompurify';
import { PreferentialOptions, ProxyPreferentialOptions } from './VotingPreferentialComponents';
import CloseButton from './../components/CloseButton'

const sanitise = (inputvalue) => {
    let clean = DOMPurify.sanitize(inputvalue);
    return { __html: clean };
}

export default class ContestSlider extends Component {
    static displayName = ContestSlider.name;

    constructor(props) {
        super(props);
        this.state = {
            attendingCheck: true
        }

    }

    componentDidMount() {
        document.title = "CESJoinIN - Voting";
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        return (
            <ContestVote />
        )
    }
}

export class ContestVote extends Component {
    static displayName = ContestVote.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            ballots: [],
            isVoteError: false,
            voteStatus: 'nothingyet',
            isPaneOpen: true
        }
    }

    componentDidMount() {
        if (this.context.fullState.contextLoading === false) {
            this.populateVoteContent();
        } else {
            this.setState({
                contextLoading: this.context.fullState.contextLoading
            })
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate() {
        if (this.context.fullState.contextLoading === false && this.context.fullState.contextLoading !== this.state.contextLoading) {
            const data = this.context.voteData;
            if (!data.length > 1) {
                this.populateVoteContent();
            }
            this.setState({
                contextLoading: this.context.fullState.contextLoading
            })
        }

        if (this.context.fullState.voteStatus !== this.state.voteStatus) {
            this.setState({
                loading: true
            });
            const data = this.context.voteData;
            if (data.length > 1) {
                this.setState({
                    loading: false
                })
            } else {
                this.populateVoteContent();
            }
            this.setState({ voteStatus: this.context.fullState.voteStatus });
        }
    }

    whyCantYouVote = (ballot) => {
        const voterStatus = this.context.user.isVoter && !this.context.user.hasPollCard;
        //why can't the user vote
        const voterHasPreMeetingVote = this.context.user.hasPreVote;
        var userRole = 'GUEST';
        if (!!this.context.user.role) {
            userRole = this.context.user.role.toUpperCase();
        }
        return (
            ballot.contestStatusId === 3 && (voterStatus === false || ballot.canVote === false || ballot.hasProxy === true) &&
            (userRole === 'NONVOTINGMEMBER' ? <p>You are not able to vote on this item because you do not have voting rights</p> :
                userRole === 'GUEST' ? <p>You are not able to vote on this item because you are attending the meeting as a guest</p> :
                    this.context.user.hasPollCard ? <p>You are not able to vote on this item because a poll card or voting device has been issued to you</p> :
                        ballot.hasProxy ? <p>You are not able to vote on this item because you have already submitted voting instructions</p> :
                            voterHasPreMeetingVote ? <p>You are not able to vote on this item because you have already cast your vote</p> :
                                ballot.canVote === false ? <p>You are not eligible to vote on this item</p> :
                                    <p>You are not able to vote on this item because there is a role and permission error</p>

            )
        )
    }

    immediateVoteItems = (ballot) => {
        const voterStatus = this.context.user.isVoter && !this.context.user.hasPollCard;
        return (
            <Accordion className={ballot.contestTypeId === 1 ? "accordionFullWidth resolutionAccordion" : "accordionFullWidth xVoteAccordion"}>
                <Fragment key={ballot.displayOrder}>
                    <ContestText ballot={ballot} voteState={ballot.contestStatusId} voterStatus={voterStatus} />
                    {ballot.contestStatusId === 3 && ballot.isProxy === true && voterStatus === true ? <h5>Your vote</h5> : ''}
                    {ballot.contestStatusId === 3 && ballot.contestTypeId === 1 && voterStatus === true && ballot.hasProxy === false && ballot.canVote === true && !(this.context.fullState.attendanceStatus && ballot.enableShowOfHands) && <ResolutionOptions ballot={ballot} />}
                    {ballot.contestStatusId === 3 && ballot.contestTypeId === 2 && voterStatus === true && ballot.hasProxy === false && ballot.canVote === true && <XvoteOptions ballot={ballot} />}
                    {ballot.contestStatusId === 3 && ballot.contestTypeId === 3 && voterStatus === true && ballot.hasProxy === false && ballot.canVote === true && <PreferentialOptions ballot={ballot} />}
                    {ballot.contestStatusId === 3 && ballot.contestTypeId === 1 && voterStatus === true && ballot.hasProxy === false && ballot.canVote === true && this.context.fullState.attendanceStatus && ballot.enableShowOfHands &&
                        <p>You have registered as being in the room.  Your vote will be counted by show of hands only.</p>}
                    {this.whyCantYouVote(ballot)}
                    {ballot.contestStatusId === 3 && ballot.contestTypeId === 1 && ballot.isProxy === true && !this.context.switches.zeroAsBlank && <ProxyOptions ballot={ballot} />}
                    {ballot.contestStatusId === 3 && ballot.contestTypeId === 2 && ballot.isProxy === true && !this.context.switches.zeroAsBlank && <ProxyXvoteOptions ballot={ballot} />}
                    {ballot.contestStatusId === 3 && ballot.contestTypeId === 3 && ballot.isProxy === true && !this.context.switches.zeroAsBlank && <ProxyPreferentialOptions ballot={ballot} />}
                    {ballot.contestStatusId === 4 && <p>Voting has been paused</p>}
                </Fragment>
            </Accordion>
        )
    }

    continuousVoteItems = (ballot) => {
        const voterStatus = this.context.user.isVoter && !this.context.user.hasPollCard;
        return (
            <Accordion className="accordionFullWidth" >
                <Card.Header>
                    <div className="endButton">
                        <Row>
                            <Col md="8" className="d-flex justify-content-between">
                                <h3>{ballot.title}</h3>
                                <div className="voteindicator">
                                    {voterStatus === true && ballot.hasProxy === false && ballot.canVote === true && (parseInt(ballot.attendeesVote) > 0 ?
                                        <FontAwesomeIcon title="You have voted in this contest" className="voteComplete" icon={faCheckCircle} /> :
                                        <FontAwesomeIcon title="You have not yet voted in this contest" className="voteAlert" icon={faBell} />)}
                                </div>
                            </Col>
                            <Col md="4">
                                <ContestToggle eventKey={"continuous" + ballot.contestID} />
                            </Col>
                        </Row>
                    </div>
                    <Accordion.Collapse eventKey={"continuous" + ballot.contestID}>
                        <Card.Body>
                            {this.immediateVoteItems(ballot)}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card.Header>
            </Accordion>
        )
    }

    render() {
        var ballots = this.context.voteData;
        var loading = this.state.loading;

        if (loading === true || this.state.contextLoading === true) {
            return (
                <Fragment>
                    <div className='loader' />
                </Fragment>
            )
        }

        else if (this.context.switches.enableVoting === false) {
            return (
                <Fragment>
                    <div className="row scrollFix">
                        <div className="cloud scrollContainer">
                            <div className="d-flex justify-content-between">
                                <h2>Voting</h2>
                                <CloseButton />
                            </div>
                            Voting is not available in this meeting
                        </div>
                    </div>
                </Fragment>
            )
        }

        else if (ballots === null || this.state.voteStatus === "nothingyet") {

            return (
                <div className="row scrollFix">
                    <div className="cloud scrollContainer">
                        <div className="d-flex justify-content-between">
                            <h2>Voting</h2>
                            <CloseButton />
                        </div>
                        <p>
                            There are no items to vote on at the moment. You will be notified when a vote opens.
                        </p>
                        <p>
                            Please do not navigate away from the CESjoinIN website as you may miss the voting notifications.
                        </p>
                    </div>
                </div>
            )
        }

        else {
            return (
                <div className="row scrollFix">
                    <div className="cloud scrollContainer">
                        <div className="d-flex justify-content-between">
                            <h2>Voting</h2>
                            <CloseButton />
                        </div>
                        {ballots.filter((a) => (a.contestStatusId > 1 && a.contestStatusId < 6 && a.isactive) || (a.isContinuous && (a.contestStatusId === 3 || a.contestStatusId === 4))).length === 0 &&
                            <Fragment>
                                <p>
                                    There are no items to vote on at the moment. You will be notified when a vote opens.
                                </p>
                                <p>
                                    Please do not navigate away from the CESjoinIN website as you may miss the voting notifications.
                                </p>
                            </Fragment>
                        }
                        {ballots.filter((a) => a.isactive && a.contestStatusId === 2).length >= 1 && <h2>Item:</h2>}
                        {ballots.filter((a) => a.isactive && !a.isContinuous && a.contestStatusId === 3).length >= 1 && <h5>Vote Now</h5>}

                        {ballots.filter((a) => a.isactive && a.contestStatusId > 1).sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map(ballot =>
                            <div className="onRequestContest" key={ballot.displayOrder}>
                                {ballot.contestStatusId === 1 ? "This contest is not open yet" : ''}
                                {(ballot.contestStatusId === 2 || ballot.contestStatusId === 3 || ballot.contestStatusId === 4) && <Fragment>
                                    <div className="cloudVoting h-100">
                                        {this.immediateVoteItems(ballot)}
                                    </div>
                                </Fragment>}

                                {ballot.contestStatusId === 5 && ballots.filter((a) => (a.isContinuous && (a.contestStatusId === 3 || a.contestStatusId === 4))).length === 0 && <ContestClosed />}
                            </div>
                        )}


                        {ballots.filter((a) => a.isContinuous && (a.contestStatusId === 3 || a.contestStatusId === 4)).length > 0 &&
                            <Fragment><hr />
                                <h5>Open Items</h5>
                            </Fragment>}
                        {ballots.filter((a) => a.isContinuous && (a.contestStatusId === 3 || a.contestStatusId === 4)).sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map(ballot =>
                            <div className="continuousContest" key={ballot.displayOrder}>
                                {this.continuousVoteItems(ballot)}
                            </div>
                        )}

                    </div>
                </div>
            )
        }
    }



    async populateVoteContent() {
        await this.context.getVoteData('voting');
        const data = this.context.voteData;
        if (data) {
            this.setState({
                loading: false
            })
        }
    }
}

export class ContestText extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const ballot = this.props.ballot;
        return (
            <Fragment>
                <div className="resBlock" key={ballot.contestID}>
                    <div className="contestTitle">
                        {!ballot.isContinuous ?
                            (ballot.description && ballot.description.length > 0 ?
                                (<div>
                                    <Card.Header>
                                        <div className="endButton">
                                            <div>
                                                <h3>{ballot.title}</h3>
                                            </div>
                                            <div>
                                                <ContextAwareToggle eventKey={ballot.contestID} />
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={ballot.contestID}>
                                        <Card.Body>
                                            <div>
                                                <div dangerouslySetInnerHTML={sanitise(ballot.description)} />
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </div>
                                )
                                : (<div><h3>{ballot.title}</h3></div>)
                            ) : (ballot.description && ballot.description.length > 0 &&
                                (<div>
                                    <div dangerouslySetInnerHTML={sanitise(ballot.description)} />
                                </div>
                                ))
                        }

                    </div>
                    {ballot.showNumSeats && (ballot.contestTypeId === 2 || ballot.contestTypeId === 3) && this.props.voteState === 3 && this.props.voterStatus === true &&
                        <Fragment>
                            <p>There {ballot.contestSeats === 1 ? "is" : "are"} {ballot.contestSeats} {ballot.votingFor}{ballot.contestSeats !== 1 && "s"} to elect. </p>
                        </Fragment>
                    }

                    {ballot.showMaxVotes && ballot.contestTypeId === 2 && this.props.voteState === 3 && this.props.voterStatus === true &&
                        <Fragment>
                            {ballot.canVote === true && <p>You may vote for {ballot.maxVotes !== 1 && "up to"} {ballot.maxVotes} {ballot.votingFor}{ballot.maxVotes === 1 ? " only" : "s"}.</p>}
                        </Fragment>
                    }
                    {ballot.publishTypeId !== 1 && this.context.switches.displayVoteWeightToUser === true && ballot.canVote === true &&
                        <Fragment>
                            <p className="weighting"><strong>Your vote weight is {ballot.voteWeight}</strong>.</p>
                        </Fragment>
                    }
                </div>
            </Fragment>
        )
    }
}

class ContestClosed extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <div className="cloud">
                    <p>
                        There are no items to vote on at the moment. You will be notified when a vote opens.
                    </p>
                    <p>
                        Please do not navigate away from the CESjoinIn website as you may miss the voting notifications.
                    </p>
                </div>
            </Fragment>
        )
    }
}



function ContextAwareToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    const baseStyle = {
        border: 0,
        width: '120px'
    }
    const activeStyle = {
        border: 0,
        width: '120px'
    }

    const buttonOpen = () => {
        return (
            <div className="readMore" aria-label="Expand to read more">
                Read more&nbsp;
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
        )
    }
    const buttonClose = () => {
        return (
            <div className="readMore" aria-label="Collapse to read less">
                Read less&nbsp;
                <FontAwesomeIcon icon={faChevronUp} />
            </div>
        )
    }

    return (
        <button
            type="button"
            style={isCurrentEventKey ? activeStyle : baseStyle}
            onClick={decoratedOnClick}
            className="btn btn-sm btnExpand"
        >
            {isCurrentEventKey ? buttonClose() : buttonOpen()}
        </button>
    );
}

function ContestToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    const baseStyle = {
        border: 0,
        width: '100%'
    }
    const activeStyle = {
        border: 0,
        width: '100%'
    }

    const buttonOpen = () => {
        return (
            <div className="readMore">
                Show&nbsp;
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
        )
    }
    const buttonClose = () => {
        return (
            <div className="readMore">
                Hide&nbsp;
                <FontAwesomeIcon icon={faChevronUp} />
            </div>
        )
    }

    return (
        <button
            type="button"
            style={isCurrentEventKey ? activeStyle : baseStyle}
            onClick={decoratedOnClick}
            className="btn btn-sm btnExpand contestExpand"
            aria-label={isCurrentEventKey ? "Collapse to read less" : "Expand to read more"}
        >
            {isCurrentEventKey ? buttonClose() : buttonOpen()}
        </button>
    );
}

ContestVote.contextType = UserContext;
ContestText.contextType = UserContext;
ContestSlider.contextType = UserContext;
ProxyOptions.contextType = UserContext;
ResolutionOptions.contextType = UserContext;