import React from 'react';
import { faComments, faGavel, faUser, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper } from '@fortawesome/free-regular-svg-icons';
import DateTimeManager from '../../Common/DateTimeManager';
import DOMPurify from 'dompurify';
import ScrollToBottom from 'react-scroll-to-bottom';

const Messages = ({ messageList }) => {

    const setMessageIcon = (messageTypeId) => {
        let icon;
        switch (messageTypeId) {
            case 1:
                icon = faComments;
                //classType = "genChat"
                break;
            case 2:
                icon = faHandPaper;
                //classType = "speakLive"
                break;
            case 3:
                icon = faGavel;
                //classType = "pointOrder"
                break;
            case 4:
                icon = faQuestionCircle;
                //classType = "techSupport"
                break;
            default:
                break;

        }
        return (
            <span className="userIcon" role="dialog" ><FontAwesomeIcon icon={icon} /></span>
        )
    }

    const setBubbleClass = (messageTypeId) => {
        let classTypeName
        switch (messageTypeId) {
            case 1:
                classTypeName = "chatBubbleLeft";
                break;
            case 2:
                classTypeName = "chatBubbleLeftSpeak";
                break;
            case 3:
                classTypeName = "chatBubbleLeftPoint";
                break;
            case 4:
                classTypeName = "chatBubbleLeftTech";
                break;
            default:
                break;
        }
        return classTypeName;
    }

    const SantitisedMessage = (message) => {
        let cleanMessage = DOMPurify.sanitize(message);
        cleanMessage = cleanMessage.replace(/<a\shref=/g, "<a target='_blank'  href=");
        return { __html: cleanMessage }
    }

    return (
        <ScrollToBottom className="messageListwrapper" followButtonClassName="scrollBtn">
            <div tabIndex="0" id="messagesList">
                {
                    messageList && messageList.length > 0 &&
                    messageList.map((listMessage, index) =>
                        <div className="chatContainer" key={listMessage.id}>
                            <div className={listMessage.messageDirection === 0 ? setBubbleClass(listMessage.messageTypeId) : "chatBubbleRight"} >
                                <div className="messageRec messageRecUser">
                                    {listMessage.messageDirection === 0 ?
                                        <span>{setMessageIcon(listMessage.messageTypeId)}
                                            {listMessage.messageTypeId > 1 ?
                                                <span className="chatUsername"></span> :
                                                <span className="chatUsername"> You</span>
                                            }

                                        </span> :
                                        <span className="adminIcon"><FontAwesomeIcon icon={faUser} />
                                            <span className="chatUsername"> Admin</span>
                                        </span>
                                    }

                                    <span className="chatDate">{new DateTimeManager(listMessage.messageDate).getTimeDefaultFormat()}</span>
                                </div>
                                {listMessage.messageDirection === 2 ?
                                    <div className="messageRec messageRecMsg">
                                        <div dangerouslySetInnerHTML={SantitisedMessage(listMessage.message)} />
                                    </div>

                                    :
                                    <div className="messageRec messageRecMsg">{listMessage.message}</div>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </ScrollToBottom>
    )
}

export default Messages;